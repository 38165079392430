import React from 'react';
import './Resume.scss';
import resumeData from '../data/resume.json';
import Button from './Button';
import RecentPosts from './RecentPosts';

const Resume = () => {
  return (
    <div id="resume" data-scroll-section="resume">

      {/* Bio */}
      <div className="resume-container resume-container__bio">
        <div className="grid">
          <div className="grid-col" data-scroll data-scroll-speed="2">
          <div className="avatar">
            <img src={resumeData.avatar} alt="Avatar" className="avatar" />
          </div>
          <div className="resume-header">
            <h1>
              {resumeData.name}
            </h1>
            <h3>
              {resumeData.title}
            </h3>
            <div className="resume-content">
              <div className="social-links">
                {resumeData.links.map((link, index) => (
                  <a key={index} href={link.url} target="_blank" rel="noopener noreferrer">
                    <i className={`fab fa-${link.icon}`}></i> {link.text}
                  </a>
                ))}
              </div>
              <div className="contact-info">
                <a href={`mailto:${resumeData.email}`} target="_blank" rel="noopener noreferrer">
                  <p>{resumeData.email}</p>
                </a>
              </div>
            </div>
            </div>
            <div
              data-scroll data-scroll-speed="1"
              dangerouslySetInnerHTML={{ __html: resumeData.bio }}>
            </div>
            {/* <ScrollDownIndicator /> */}
          </div>
        </div>
      </div>

      {/* Skills Stripe */}
      <div className="resume-skills" id="skills-section">
        <div className="animated-stripes-container" data-scroll data-scroll-speed="4">
          <div className="animated-stripe">
            <span className="stripe-text">
              {resumeData.skills.filter(skill => !skill.disabled).map((skill) => (
                <span className="stripe-text--item" key={skill.name + '-1'}>
                  {skill.name}
                </span>
              ))}
            </span>
          </div>
          <div className="animated-stripe">
            <span className="stripe-text">
              {resumeData.skills.map((skill) => (
                <span className="stripe-text--item" key={skill.name + '-2'}>
                  {skill.name}
                </span>
              ))}
            </span>
          </div>
        </div>
      </div>
      
      {/* Highlight Sections */}
      <div className="resume-container resume-container__highlights">
        <div className="grid">
          {resumeData.sections.map((section, index) => (
            <div key={index} className="resume-section">
              <h3 data-scroll data-scroll-speed="1">
                {section.title}
              </h3>
              <p  data-scroll data-scroll-speed="2">
                {section.content}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Apps Section */}
      <div className="resume-container resume-container__apps">
        <h2 data-scroll data-scroll-speed="1">Apps</h2>
        <div className="apps-grid">
          {resumeData.apps.map((app, index) => (
            <div key={index} className="app-item" title={app.description}>
              <a href={app.url} target="_blank" rel="noopener noreferrer">
                <div className="app-icon">
                  <img src={app.icon} alt={`${app.name} icon`} />
                </div>
                <h3>{app.name}</h3>
              </a>
            </div>
          ))}
        </div>
      </div>

      {/* Recent Posts Section */}
      <div className="resume-container">
        <RecentPosts></RecentPosts>
      </div>
      
      {/* Contact Section */}
      <div className="resume-container resume-container__contact">
        <h2 data-scroll data-scroll-speed="1">
          Get in Touch
        </h2>
        <p data-scroll data-scroll-speed="1">
          Feel free to contact me for any questions, estimates or collaborations.
        </p>
        <div className="cta-container" data-scroll data-scroll-speed="2">
          <a href={`mailto:${resumeData.email}`} target="_blank" rel="noopener noreferrer">
            <Button
              color="light"
              variant="invert"
            >
              Contact me
            </Button>
          </a>
        </div>
      </div>

    </div>
  );
};

export default Resume;
